import Link from 'next/link'
import { filterSubNavLinkData } from 'library/filters/navigation'

const SubscriptionList = ({ subscriptions }) => {

  // List items
  const items = []

  /**
   * NOTE:
   * Normally the subscriptions prop will always be an array of subscription entries from Contentful
   * However, for the Exclusives tab we occassionally pass through custom external links
   */

  // Bulid list
  for (const item of subscriptions) {

    // Build links by entry type
    switch (item.cfType) {

      // Custom links
      case 'navigationItem':

        if (item.customUrl) {
          items.push(
            <li key={item.cfId}>
              <a href={item.customUrl} target="_blank">{item.name}</a>
            </li>
          )
        }
        break

      // Subscription is always default
      default:
        let type = 'subscription'
        if (item.type && item.type === 'Membership') type = 'membership'
        const linkHref = filterSubNavLinkData(`/${type}/${item.itemNumber.toLowerCase()}`, item)
        items.push(
          <li key={item.cfId}>
            <Link href={linkHref}>
              {item.title}
            </Link>
          </li>
        )
        break
    }
  }

  return (
    <ul className="subscription-list no-style">
      {items}
    </ul>
  )
}

export default SubscriptionList